<template>
  <div class="myTeam animated" id="myTeam" :class="{'bounceInLeft':teamPk}">
    <div v-if="teamPk">
    <div class="cultureLine">
      <div class="greenBox"></div>
      <div class="greenTxt">我的团队<br/><span style="color: #666666">Team Introduction</span></div>
      <div class="greenBox"></div>
    </div>
    <div class="teamContent">
      <div class="teamLeft">
        <img src="../../assets/imgs/home/banner1.png">
      </div>
      <div class="teamRight">
        <div class="">
          <div class="txtOne">Xxx</div>
          <div class="txtTwo">产品经理</div>
          <div class="txtThree">行业经验7年</div>
        </div>
        <div class="lineTwo">
          7年餐饮管理行业经验，深耕大型食堂餐馆领域，有
          丰富的实战经验，曾独自完成xxx公司2000人规模的
          食堂管理解决方案，并在投入使用后出现立竿见影的
          效果。</div>
        <div class="lineThree">个人荣誉</div>
        <div class="lineFour">
          <div class="box">优秀干部</div>
          <div class="box">优秀干部</div>
          <div class="box">优秀干部</div>
          <div class="box">优秀干部</div>
          <div class="box">优秀干部</div>
          <div class="box">优秀干部</div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myTeam",
  props:{
    teamPk:Boolean
  }
}
</script>

<style lang="scss" scoped>
.myTeam{
  position: relative;
  width: 1920px;
  height: 700px;
}
.teamContent{
  width:900px;
  height: 350px;
  margin: 0 auto;
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
}
.teamLeft{
  width: 410px;
  height: 350px;
  img{
    width: 100%;
    height: 100%;
  }
}
.teamRight{
  width: 410px;
  height: 350px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .lineOne{
    width: 366px;
    height: 52px;
    background: #EEAA3D;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 30px;
    padding: 10px;
    box-sizing: border-box;
  }
  .lineTwo{
    color: #999999;
    font-size: 16px;
    width: 366px;
  }
  .lineThree{
    width: 366px;
    height: 24px;
    background: #EEAA3D;
    font-size: 16px;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .lineFour{
    font-size: 16px;
    width: 366px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .box{
      flex: 0 0 30%;
      width: 78px;
      height: 27px;
      background:#EEAA3D;
      text-align: center;
    }
  }

}
.cultureLine{
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  span{
    font-size: 20px;
  }
}
.greenBox{
  width: 460px;
  height: 10px;
  background: #A7C69C;
}
.greenTxt{
  position: relative;
  width: 280px;
  font-size: 30px;
  text-align: center;
  top: -18px;
  color: #666666;

}
.cultureMain{
  margin: 0 auto;
  width: 950px;
  font-size: 14px;
  color: #999999;
}

</style>
