<template>
  <div class="companyCulture animated" id="companyCulture" :class="{'bounceInLeft':comPk}" >
    <div v-if="comPk">
    <div class="cultureLine" >
      <div class="greenBox"></div>
      <div class="greenTxt">公司简介<br/><span style="color: #666666">corporate culture</span></div>
      <div class="greenBox"></div>
    </div>



        <div class="cultureMain" >
      浙江国通电气科技有限公司是一家集互联网软件,智能硬件于一体的科技公司；是领先的大型企业后厨管理系统解决方案提供商。致力于帮助企业管理降本增效，改革创新，数字化赋能。国通电气拥有业内领先的自主核心技术和可持续研发能力，为国家电网公司，政府综合服务机构，医疗机构，学校等提供合适的细分产品与专业的行业解决方案。国通电气部署在国家电网公司的整体解决方案获得国网浙江公司创新管理大赛二等奖，还因帮助企业节源节流，管理创新，接受了浙江卫视的采访。
        </div>
    <div class="banner2">
      <el-carousel :interval="4000" type="card"  indicator-position="none" ref="cardShow">
        <el-carousel-item v-for="(item,index) in banners2" :key="index"  >
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
      <i class="el-icon-arrow-left leftArrow" @click="ban2Change(0)"></i>
      <i class="el-icon-arrow-right rightArrow" @click="ban2Change(1)"></i>

    </div>
    <div class="bubbleList">
      <div class="bubbleEver">
        <div class="bubbleImg">
          <img src="../../assets/imgs/home/5G.png">
        </div>
        <div class="bubbleTxt">
          5G大数据
          <span>
            5g big data
          </span>
        </div>
      </div>
      <div class="bubbleEver">
        <div class="bubbleImg">

          <img src="../../assets/imgs/home/AIshibie.png">
        </div>
        <div class="bubbleTxt">
          AI识别
          <span>
            Innovative achievements
          </span>
        </div>
      </div>
      <div class="bubbleEver">
        <div class="bubbleImg">

          <img src="../../assets/imgs/home/kejifuneng.png">
        </div>
        <div class="bubbleTxt">
          科技赋能
          <span>
            Technology empowerment
          </span>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyCulture",
  props:{
    comPk:Boolean
  },
  data(){
    return{
      banners2:[
        require("../../assets/imgs/home/gsjj.png"),
        require("../../assets/imgs/home/gsjj2.png"),
        require("../../assets/imgs/home/gsjj3.png"),

      ],
    }
  },
  methods:{
    ban2Change(option){
      if(option==0){
        this.$refs.cardShow.prev()
      }else{
        this.$refs.cardShow.next()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.companyCulture{
  position: relative;
  width: 1920px;
  height: 800px;
  margin-bottom: 100px;
}
.cultureLine{
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
  span{
    font-size: 20px;
  }
}
.greenBox{
  width: 460px;
  height: 10px;
  background: #A7C69C;
}
.greenTxt{
  position: relative;
  width: 280px;
  font-size: 30px;
  text-align: center;
  top: -18px;
  color: #666666;


}
.cultureMain{
  margin: 0 auto;
  width: 950px;
  font-size: 14px;
  color: #999999;
}
.banner2{
  position: relative;
  width: 1200px;
  height: 250px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .el-carousel__arrow--left{

  }
  /deep/ .el-carousel__arrow--right{

  }
  /deep/ .el-carousel__arrow{
    opacity: 0;
  }
  /deep/ .el-carousel__container{
    width: 900px;
    height: 250px;
    /deep/ .el-carousel__item{
      width: 450px;
      height: 250px;
    }
    /deep/ .el-carousel__item--card{
      width: 450px;
      height: 250px;
    }
    img{
      width: 450px;
      height: 250px;
    }
  }

}
.leftArrow{
  position: absolute;
  left: 5%;
  top: 45%;
  color: #A7C69C;
  font-size: 30px;
  cursor: pointer;
}
.rightArrow{
  position: absolute;
  right: 5%;
  top: 45%;
  color: #A7C69C;
  font-size: 30px;
  cursor: pointer;
}
.bubbleList{
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 230px;
  display: flex;
  justify-content: space-around;
  .bubbleEver{
    position: relative;
    width: 200px;
    height: 230px;
    .bubbleImg{
      margin: 0 auto;
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      margin-bottom: 30px;

      background: #A7C69C;
    }
    img{
      width: 70px;
      height: 70px;
    }
    .bubbleTxt{
      width: 100%;
      text-align: center;
      font-size: 16px;
      span{
        display: block;
        font-size: 14px;
        margin-top: 10px;
        color: #999999;
      }
    }
  }
  .bubbleEver:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.5s;
  }
}
</style>
