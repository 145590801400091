<template>
<div class="dynamic">
  <div class="dyTitleLine">
    <div class="yellowTitle">浙江国通电气科技有限公司</div>
    <div class="whiteTitle">Industry information | 行业资讯</div>
    <div class="fakerBox"></div>
  </div>
  <div class="dyContent">
  <div class="newsLeft">
    <news-big :img-path="newsData[0].imgPath" :news-title="newsData[0].newsTitle" :news-content="newsData[0].newsContent"></news-big>
  </div>
  <div class="newsRight">
    <news-small :img-path="newsData[1].imgPath" :news-title="newsData[1].newsTitle" :news-content="newsData[1].newsContent" :newsIndex="1"></news-small>
    <news-small :img-path="newsData[2].imgPath" :news-title="newsData[2].newsTitle" :news-content="newsData[2].newsContent" :newsIndex="2"></news-small>
    <news-small :img-path="newsData[3].imgPath" :news-title="newsData[3].newsTitle" :news-content="newsData[3].newsContent" :newsIndex="3"></news-small>
  </div>
  </div>
</div>
</template>

<script>
import NewsBig from "./newsBig";
import NewsSmall from "./newsSmall";
export default {
  name: "realInfo",
  components: {NewsSmall, NewsBig},
  data(){
    return{
      newsData:[
        {
          imgPath:require("../../assets/imgs/home/news/ind1.jpg"),
          newsTitle:"第二十届上海国际食品加工与包装机械展",
          newsContent:'日前在国家会展中心（上海）闭幕的第二十届上海国际食品加工与包装机械展上，浙江国通电气科技有限公司展出“数字大脑智慧健康餐饮管理系统”和“AI食安溯源系统”，以其在“降本增效”、“保障食品安全”、“杜绝小微权利”等方面独到的产品构思，以及融汇AI人工智能、大数据分析等技术的优异系统性能，获得与会客户的一致好评，并已与多家企业达成合作意向。'
        },
        {
          imgPath:require("../../assets/imgs/home/news/ind2.jpg"),
          newsTitle:"政府助力 企业腾飞",
          newsContent:'杭州市未来科技城梦想小镇作为当下浙江创新创业、促进经济数字化转型的新高地，每年举办的“创业先锋营”大赛都吸引省内外几百家优秀企业前来角逐最后的“金钥匙”奖。<br/>' +
              '<br/>' +
              '浙江国通电气科技有限公司研发的“数字大脑智慧健康餐饮管理系统”，经专家组评审，在闯过初审后的几十家优秀企业项目中脱颖而出，斩获金钥匙奖，享受政府扶植优惠补贴政策入驻梦想小镇，为企业下一步的高速发展提供有效助力。'
        },
        {
          imgPath:require("../../assets/imgs/home/news/ind3.png"),
          newsTitle:"党史小故事 | 关于一封节约粮食的联名！",
          newsContent:' “1934年3月20日，中央苏区机关报《红色中华》刊登了一封联名信。当时一批从“白区”来苏区工作的干部，就《红色中华》提出节省运动的号召，向编辑部去信告知他们具体响应号召的办法。信是这么说的：我们是从白区来的，我们在苏区没有分田，但是我们为着革命战争，使我们能在持久战中取得彻底胜利，愿意：一，每天节省二两米，使前方红军吃饱，好打胜仗；二，今年公家不发我们热天衣服，把这些衣服给新战士穿。我们要求其他白区来的同志，和在苏区分了田的同志，都同我们一起，来响应《红色中华》的节省号召！<br/>' +
              '<br/>' +
              '    由于这些“白区”来的同志，在苏区没有土地等生产资料，其生活必需品主要靠单位供给。这封信的署名人一共有二十三位，其中有不少我们熟悉的名字：陆定一、邓颖超、博古、毛泽覃、成仿吾等。当时的中央苏区干部，不仅自发节约粮食，甚至在有的情况下，自带粮食上班开会，不要公家发放的粮食。笔者寻到1933年11月26日出版的《红色中华》中刊登了一则短消息：“杨殷县泮溪区长教乡，日前各当选的代表集中开会时，一致要求自带伙食，而把公家发给的伙食费，捐助红军战费”，随后简要介绍了泮溪区苏维埃新当选代表的提案，基本都与农业生产有关。”摘自“党史小故事丨一封节约粮食的联名”。'
        },
        {
          imgPath:require("../../assets/imgs/home/news/ind4.png"),
          newsTitle:"数字大脑智慧健康餐饮是如何做到， 传统式食堂唾弃粗放式管理模式？",
          newsContent:' 随着人工智能、大数据的发展，越来越多的行业加入了信息化的行列。尤其是与人们生活息息相关的衣食住行。最近因新冠疫情热度持续走高，在信息化和传统食堂弊端不断的双重影响下，“数字大脑智慧健康餐饮”也随着大环境趋势情况下应运而生的。  与其说是“数字大脑智慧健康餐饮”取代了传统食堂，倒不如说，“数字大脑智慧健康餐饮管理系统”是传统食堂在信息化时代的转型。传统食堂食材浪费，无法闭环管理，就餐体验差等问题都随着“数字大脑智慧健康餐饮”的出现而得到解决。<br/>' +
              '<br/>' +
              '传统式食堂有哪些粗放式管理模式呢？<br/>' +
              '<br/>' +
              '1.   传统食堂厨师专业水平有限、服务质量低下：受厨师规模和水平的限制，无法进行厨艺交流，且又不能更换厨师影响稳定，菜品种类更新比较慢，水平提升慢，饭菜质量难保证，服务质量难提高。<br/>' +
              '<br/>' +
              '2.   传统食堂采购成本高、管理效率低:劳动生产率管理低下，在食品原材料的采购中成本过高，在食品加工过程中浪费过多，致使食堂效率效益差，经济负担日益加重。<br/>' +
              '<br/>' +
              '3.   传统食堂缺乏有效监管、安全风险较高:团餐是一个非常微利的行业，稍微不注意就会出现管理不善，监管人员仅仅是注重注重卫生状况和基本安全防范，深层次的质量监督和质量检查是无法做到，容易造成更大的潜在风险。<br/>' +
              '<br/>' +
              '4.   传统食堂排队时间长、结账易出错:用餐时间集中，人流量大会造成食堂排队时间长，依赖人工收银，靠收银人员计价，速度慢、易出错，容易造成排长队、引发纠纷。    “数字大脑智慧健康餐饮”是由数字大脑智慧健康餐饮管理后台系统、数字大脑智慧健康餐饮管理点餐系统、AI食材溯源系统、数字大脑智慧健康餐饮管理后勤管理系统组成，每个系统除了优化传统食堂经营过程中的弊端外，还提供了整体的数据支持而食堂管理者可通过数字大脑智慧健康餐饮管理系统各个环节的详尽数据更好的管理食堂。'
        }
      ]
    }
  },
  methods:{
    newsIndexChange(option){
      this.$store.state.newsIndex=option;
      this.$router.push("newsPage")

    }
  }
}
</script>

<style lang="scss" scoped>
.dynamic{
 width: 900px;
  min-height: 400px;
  margin: 0 auto;

}
.dyTitleLine{
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  font-size: 14px;
  line-height: 50px;
  text-indent: 30px;
  overflow: hidden;
}
.yellowTitle{
  background: #EEAA3D;
  color: white;
  width: 420px;
  height: 100%;
}
.whiteTitle{
  background: white;
  color: #EEAA3D;
  width:480px;
  height: 100%;
}
.fakerBox{
  position:absolute;
  left: 390px;
  top: 0;
  width: 50px;
  height: 50px;
  background:white;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
}
.dyContent{
  width: 900px;
  min-height: 350px;

  display: flex;
}
.newsRight{
  width: 500px;
  min-height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
</style>
