<template>
<!--<div class="home" ref="home" id="home" @scroll="testScroll">-->
  <div class="home" ref="home" id="home"  @scroll="testScroll">

  <gt-header checked="sy"></gt-header>
<banner></banner>
  <programme></programme>
  <advantage></advantage>
  <choice-me></choice-me>
  <digital-manage></digital-manage>
  <partner></partner>
<gtFooter></gtFooter>
<div class="toTop" @click="toTop" v-if="topPk">
  <i class="el-icon-arrow-up topArrow"></i>
</div>
</div>

</template>

<script>
import gtHeader from "../../components/gtHeader";
import gtFooter from "../../components/gtFooter";
import Banner from "../../components/home/banner";
import CompanyCulture from "../../components/home/companyCulture";
import ProductIntroduction from "../../components/home/productIntroduction";
import SaleExp from "../../components/home/saleExp";
import MyTeam from "../../components/home/myTeam";
import NewCenter from "../../components/home/newCenter";
import ThinkTank from "../../components/home/thinkTank";
import Programme from "../../components/home/programme";
import Advantage from "../../components/home/advantage";
import ChoiceMe from "../../components/home/choiceMe";
import DigitalManage from "../../components/home/digitalManage";
import Partner from "../../components/home/partner";
export default {
  name: "home",
  components: {
    Partner,
    DigitalManage,
    ChoiceMe,
    Advantage,
    Programme,
    ThinkTank,
    NewCenter,
    MyTeam, SaleExp, ProductIntroduction, CompanyCulture, Banner, gtHeader,gtFooter},
  data(){
    return{
      topPk:false,

    }
  },
  methods:{
    toTop(){
      let timer = setInterval(() => {
        let scr=this.$refs.home.scrollTop;
        let ispeed = Math.floor(-scr/ 5)
        this.$refs.home.scrollTop=scr+ispeed;
        if (scr === 0) {
          clearInterval(timer)
        }
      }, 16)

    },
    testScroll(){

      if(this.$refs.home.scrollTop>=500){
        this.topPk=true
      }else{
        this.topPk=false

      }
    },
  },
  destroyed() {
  },
  mounted() {
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
.home{
  position: relative;
  width: 100vw;
  height: 100vh;
 overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}

/deep/ .el-carousel__container{
  width: 1920px;
  height:800px !important ;
  img{
    width: 1920px;
    height: 800px;
  }
}

.cultureLine{
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  span{
    font-size: 20px;
  }
}
.greenBox{
  width: 460px;
  height: 10px;
  background: #A7C69C;
}
.greenTxt{
  position: relative;
  width: 280px;
  font-size: 30px;
  text-align: center;
  top: -18px;
}
.cultureMain{
  margin: 0 auto;
  width: 950px;
  font-size: 14px;
  color: #999999;
}
.toTop{
  position: fixed;
  bottom: 200px;
  right:200px;
  width: 60px;
  height: 60px;
  background: #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .topArrow{
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
}
</style>
