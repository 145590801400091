<template>
<div class="programme" id="programme">
<div class="titleLine">
  <div class="lineOne">
    <img src="../../assets/newImgs/home/programme.png" alt="">
  </div>
  <div class="lineTwo">
    <img src="../../assets/newImgs/home/leftsjx.png" alt="">
    <div>行业<span>解决</span>方案</div>
    <img src="../../assets/newImgs/home/rightsjx.png" alt="">

  </div>
</div>
  <div class="content">
    <div v-for="(item,index) in boxList" :key="index">
      <div class="boxEver">
        <img :src="item.imgPath">
        <div class="boxTxt">{{item.txt}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "programme",
  data(){
    return{
      boxList:[
        {imgPath:require("../../assets/newImgs/home/jiguan.png"),txt:"政府机关"},
        {imgPath:require("../../assets/newImgs/home/qiye.png"),txt:"国有企业"},
        {imgPath:require("../../assets/newImgs/home/xuexiao.png"),txt:"大专学校"},
        {imgPath:require("../../assets/newImgs/home/yiyuan.png"),txt:"医院养老"},
        {imgPath:require("../../assets/newImgs/home/yuanqu.png"),txt:"产业园区"},
        {imgPath:require("../../assets/newImgs/home/dasha.png"),txt:"商务大厦"},
        {imgPath:require("../../assets/newImgs/home/chufang.png"),txt:"中央厨房"},
        {imgPath:require("../../assets/newImgs/home/jianyu.png"),txt:"监狱食堂"},

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.programme{
  position: relative;
  width: 1920px;
  height: 770px;
  background: white;
}
.titleLine{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .lineOne{
    img{
      width: 210px;
    }
  }
}
.lineTwo{
  display: flex;
  color: #A7C69C;
  font-size: 36px;
  align-items: center;
  span{
    border-bottom: 4px solid #EEAA3D;
  }
  img{
    width: 45px;
  }
}
.content{
  width:1200px;
  height: 400px;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.boxEver{
  width: 185px;
  height: 185px;
  border-radius: 20px;
  background: #EFEFEF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 50px;

  align-items: center;
  img{
    width: 100px;
  }
}
.boxTxt{
  font-size: 18px;
  text-align: center;
  color: #999999;
  margin-top: 10px;
}
</style>
