<template>
<div class="digitalManage" id="digitalManage">
<div class="greenLeft">
<div class="leftTxt">
  Data management <br>
  数据化管理
</div>
</div>
  <div class="yellowRight">
   <div class="rightTxt">
     <div class="rightOne">非接触智能存/取餐</div>
     <div class="rightTwo">
       快速，高效，无接触式的取餐过程替代传统的排队就餐；恒温保存，减
       少人员成本，远程监控调取。
       拥有快速线上配餐能力，实现餐主一键取餐模式，以及一键查询附近
       可用柜机和空餐格，获取订单取走回调通知，轻松闭环管理。</div>
   </div>
  </div>
  <div class="digPic">
    <img src="../../assets/newImgs/home/digPic.png" alt="">
  </div>
</div>
</template>

<script>
export default {
  name: "digitalManage"
}
</script>

<style lang="scss" scoped>
.digitalManage{
  position: relative;
  width: 1920px;
  height: 400px;
background: url("../../assets/newImgs/home/digBanner.png");
  background-size: 100% 100%;
  display: flex;
}
.greenLeft{
  position: relative;
  width: 960px;
  height: 400px;
}
.yellowRight{
  position: relative;
  width:960px;
  height: 400px;
}
.leftTxt{
  position: absolute;
  top: 70px;
  left: 410px;
  color: white;
  font-size: 36px;
}
.rightTxt{
  position: absolute;
  right: 350px;
  top: 80px;
  color: white;
  max-width: 440px;
  .rightOne{
  font-size: 36px;
    margin-bottom: 20px;

  }
  .rightTwo{
    font-size: 16px;
  }
}
.digPic{
  position: absolute;
  left:800px;
  top: 88px;
  img{
    width: 210px;
    height: 270px;
  }
}
</style>
