<template>
  <div class="banner" id="coreProducts">
  <el-carousel ref="homeBanner" @change="bannerChange" height="800px" >
    <el-carousel-item v-for="(item,index) in banners" :key="index"  >
      <img :src="item">
    </el-carousel-item>
  </el-carousel>
    <div class="checkLine">
      <div class="checkMain">
      <div class="checkEver" :class="{'checkedCss':(checkPk==item.value)}" v-for="(item,index) in  checkData"  @click="checkChange(index)" :key="index">
        {{item.label}}
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  data(){
    return{
     banners:[
       require("../../assets/newImgs/home/banner1.png"),
       require("../../assets/newImgs/home/banner1.png"),
       require("../../assets/newImgs/home/banner1.png"),
       require("../../assets/newImgs/home/banner1.png"),
     ],
      checkPk:"jbzx",
      checkData:[
        {
          label:"接轨政府平台",
          value:"jbzx"
        },
        {
          label:"防疫安全屏障",
          value:"szhgl"
        },

        {
          label:"数字化创新管理",
          value:"cxfw"
        },
        {
          label:"助力降本增效",
          value:"kyjl"
        }
      ]
    }
  },
  methods:{
    checkChange(option){
      this.checkPk=this.checkData[option].value;
      let homeBanner=this.$refs.homeBanner;
      homeBanner.setActiveItem(option)
    },
    bannerChange(e){
      this.checkPk=this.checkData[e].value;
    }

  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  width:1920px;
  height: 920px;
  //background: #A7C69C;
}
///deep/ .el-carousel  {
//  position: absolute;
//  left: 0;
//  top: 0;
//  width:19.2rem;
//  height: 9.2rem;
//}
.el-carousel__container img{
  width:1920px !important;
  height: 920px !important;
}
/deep/  .el-carousel__item{
  width: 100%;
  height: 100%;
  /deep/ img{
    width: 100% !important;
    height: 100% !important;
  }
}

.checkLine{
  position: absolute;
  width: 100%;
  height: 95px;
  bottom:250px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.checkMain{
   width:1000px;
  height: 95px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  overflow: hidden;

  .checkEver{
    width: 250px;
    height: 95px;
    text-align: center;
    line-height: 95px;
    color: #598948;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s;
  }
}
.checkedCss{
  background: #EEAA3D;
  color: white !important;
  transition: 0.5s;
}
</style>
