<template>
<div class="newsSmall" @click="newsIndexChange">
  <div class="newsPic">
    <img :src="imgPath">
  </div>
  <div class="newsRight">
  <div class="newsTitle">{{newsTitle}}</div>
  <div class="newsContent" v-html="newsContent"></div>
  </div>
</div>

</template>

<script>
export default {
  name: "newsSmall",
  props:{
    imgPath:String,
    newsTitle:String,
    newsContent:String,
    newsIndex: Number
  },
  methods:{
    newsIndexChange(){
      this.$store.state.newsIndex=this.newsIndex;
      this.$router.push("newsPage")
    }
  }
}
</script>

<style lang="scss" scoped>
.newsSmall{
  padding-left: 5px;
  box-sizing: border-box;
  position: relative;
  width: 500px;
  min-height: 100px;
  display: flex;
}
.newsPic{
  position: relative;
  width: 110px;
  height: 80px;
  overflow: hidden;
  img{
    width:110px;
    height:80px;
  }
  margin-right: 10px;
}
.newsRight{
  color: white;
  width: 390px;
.newsTitle{
  font-size: 14px;
  margin-bottom: 5px;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
  .newsContent{
    font-size: 12px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
