<template>
<div class="advantage" id="advantage">
<div class="advLeft">
  <div class="advTitle">
    国通电气科技有限公司<br>
    <span>5大核心</span>优势
  </div>
  <div class="advContent">
    面向实际，消除管理盲点 <br>
    校企联合，持续研发能力卓越 <br>
    行业技术领先，自有知识产权 <br>
    标准化产品、个性化定制，服务丰富 <br>
    接轨政府数据平台，构建防疫安全屏障
  </div>
</div>
  <div class="advRight">
    <img src="../../assets/newImgs/home/gspic.png" alt="">
  </div>
</div>
</template>

<script>
export default {
  name: "advantage",
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
.advantage{
  position: relative;
  width: 1920px;
  height: 770px;
  background: #A7C69C;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.advLeft{
  position: relative;
  width: 600px;
  color: white;
  padding-top: 150px;
  box-sizing: border-box;
  .advTitle{
    font-size: 36px;
    color: white;
    span{
      color: #EEAA3D;
    }
  }
  .advContent{
    position: relative;
    font-size: 24px;
    margin-top: 50px;
  }
}
.advRight{
  position: relative;
  width: 650px;
  height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-120px);
  img{
   height: 800px;
    width: 600px;
  }
}
</style>
