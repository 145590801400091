<template>
<div class="choiceMe" id="choiceMe">
  <div class="titleLine">
    <div class="lineOne">
      <img src="../../assets/newImgs/home/cooperation.png" alt="">
    </div>
    <div class="lineTwo">

      <div>为什么<span>选择</span>我们？</div>


    </div>
  </div>
  <div class="choiceMain">
    <div class="choiceEver">
      <div class="choiceTitle">
        <div class="iconY"></div>
        <div class="txt">对员工有什么好处</div>
      </div>
      <div class="choiceContent">
        个性化用餐服务，做到“我的餐盘我做主”
        不出办公室就明了食堂供应，移动端便捷点餐
        减少排队，取餐灵活、方便、卫生
        健康膳食引导，助力塑造强健体魄
      </div>
    </div>
    <div class="choiceEver">
      <div class="choiceTitle">
        <div class="iconY"></div>
        <div class="txt">对企业有什么好处</div>
      </div>
      <div class="choiceContent">
        数字化管理替代传统人工，减少管理盲区
        全过程数据留痕，便于各班组量化考核，降低管理成本
        减少食材验收人员自由裁量权，标准化验收把牢食材质量关
        供应商考核更全面，食材数据可追溯
        多环节共同入手，做到防疫常态化，确保企业与员工安全
      </div>
    </div>
  </div>
  <div class="choiceBom">
    已服务超<span>20</span>家企业
  </div>
</div>
</template>

<script>
export default {
  name: "choiceMe"
}
</script>

<style lang="scss" scoped>
.choiceMe{
  position: relative;
  width: 1920px;
  height: 600px;
  background: white;
  padding-top: 50px;
  box-sizing: border-box;
}
.titleLine{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lineOne{
 img{
    width: 180px;
 }
}
.lineTwo{
  display: flex;
  color: #A7C69C;
  font-size: 36px;
  align-items: center;
  span{
    border-bottom: 7px solid #EEAA3D;
  }
}
.choiceMain{
  position: relative;
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 50px;
  .choiceEver{
    position: relative;
    width: 430px;
    min-height: 230px;
    background: rgb(237, 244, 235);
    border-radius:15px;
    color: white;
    padding: 20px 16px;
    box-sizing: border-box;
    .choiceTitle{
      display: flex;
      align-items: center;
      .iconY{
        position: relative;
        background: #EEAA3D;
      width: 8px;
        height: 20px;
        margin-right: 5px;
      }
      .txt{
        color: #666666;
        font-size: 18px;
      }
    }
    .choiceContent{
      color:#999999;
      font-size: 14px;
      margin-top: 20px;
    }
  }
}
.choiceBom{
  margin-top: 70px;
  width: 100%;
  text-align: center;
  color: #A7C69C;
  font-size: 20px;
  span{
    font-size: 40px;
    margin: 0 5px;
  }
}
</style>
