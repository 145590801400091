<template>
<div class="thinkTank animated" :class="{'bounceInRight':thinkPk}" id="thinkTank">
  <div class="outer" v-if="thinkPk">
<div class="frameLeft">
  <div class="dataBase">
    <img src="../../assets/imgs/home/database.png" alt="">
  </div>
</div>
  <div class="frameRight">

  </div>
  <div class="frameMain">
    <div class="frameTitle">三大数据库</div>
    <div class="boxLine">
      <div class="boxEver">
      <img src="../../assets/imgs/home/zk1.png">
      </div>
      <div class="boxEver">
        <img src="../../assets/imgs/home/zk2.png">
      </div>
      <div class="boxEver">
        <img src="../../assets/imgs/home/zk3.png">
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
export default {
  name: "thinkTank",
  props:{
    thinkPk:Boolean
  }
}
</script>

<style lang="scss" scoped>
.thinkTank{
  position: relative;
  width: 1920px;
  height: 800px;
  display: flex;

}
.outer{
  position: relative;
  width: 1920px;
  height: 800px;
  display: flex;

}
.frameLeft{
  width:960px;
  height: 800px;
  background:rgb(245, 245, 245);
  .dataBase{
    position: absolute;
    top: 260px;
    left: 120px;
  img{
  width: 95px;
    height: auto;
  }
  }
}
.frameRight{
  width: 960px;
  height:800px;
  background: #999999;
  background: url("../../assets/imgs/home/zkBg.png");
  background-size: 100% 100%;
}
.frameMain{
  position: absolute;
  top: 130px;
  left: 350px;
  width: 850px;
  height: 600px;
  border-radius: 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: white;
  padding: 50px;
  box-sizing: border-box;
}
.frameTitle{
  font-size: 60px;
  color: #999999;
  font-weight: bold;
}
.boxLine{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  .boxEver{
    width: 200px;
    height: 210px;
    img{
      width: 200px;
      height: 210px;
    }
  }
}
</style>
