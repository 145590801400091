<template>
  <div   class="productIntroduction animated " :class="{'bounceInUp':proPk}" id="productIntroduction" >
    <div class="outer" v-if="proPk">
    <div class="cultureLine">
      <div class="greenBox"></div>
      <div class="greenTxt">使用流程<br/><span style="color: #666666">Product introduction</span></div>
      <div class="greenBox"></div>
    </div>
    <div class="processAll">
      <div class="processEver">
        <div class="cover">
        <img src="../../assets/imgs/home/diancan.png">
        <div class="processTxt">交互式预约点餐</div>
        </div>
        <div class="back">
          <div class="backIcon">
            <img src="../../assets/imgs/home/diancan.png">
          </div>
          <span>交互式预约点餐</span><br/>
          客户可以通过点餐应用程序进行在线实时
          点餐和预约点餐，为客户提供更多选择
        </div>
      </div>
      <div class="processEver">
        <div class="cover">
        <img src="../../assets/imgs/home/yushengchanjihua.png">
        <div class="processTxt">自动生成生产计划</div>
          </div>
        <div class="back">
          <div class="backIcon">
            <img src="../../assets/imgs/home/yushengchanjihua.png">
          </div>
          <span>自动生成生产计划</span><br/>
          通过前端点餐系统数据获取结合往日数
          据进行智能推导，推出次日生产计划
        </div>
      </div>
      <div class="processEver">
        <div class="cover">
        <img src="../../assets/imgs/home/jisuan.png">
        <div class="processTxt">原材料智能反算</div>
        </div>
        <div class="back">
          <div class="backIcon">
          <img src="../../assets/imgs/home/jisuan.png">
        </div>
          <span>原材料智能反算</span><br/>
          通过智能AI系统反算功能，自动抓取菜
          品数据进行智能反算，推出生产计划和
          采购计划。

        </div>
      </div>
      <div class="processEver">
        <div class="cover">
          <img src="../../assets/imgs/home/jhgl.svg">
          <div class="processTxt">自动生成采购计划</div>
        </div>
        <div class="back">
          <div class="backIcon">
                      <img style="color: white" src="../../assets/imgs/home/jhgl.svg">
                    </div>
                    <span>自动生成采购计划</span><br/>
          通过智能AI系统反算功能，所获取的
          数据再结合厨师长对食材用量的判断
          推出次日采购计划！
        </div>
      </div>

<!--      <div class="processEsp">-->
<!--        <div class="backIcon">-->
<!--          <img src="../../assets/imgs/home/jisuan.png">-->
<!--        </div>-->
<!--        <span>自动生成采购计划</span><br/>-->
<!--        通过智能AI系统自动识别称重食材，通-->
<!--        过蓝牙传输数据，验收人员对当日进货-->
<!--        食材依次评分，-->

<!--      </div>-->
      <div class="processEver">
        <div class="cover">
        <img src="../../assets/imgs/home/jiance.png">
        <div class="processTxt">智能食材检测验收</div>
        </div>
        <div class="back"> <div class="backIcon">
          <img src="../../assets/imgs/home/jiance.png">
        </div>
          <span>智能食材检测验收</span><br/>
          通过智能AI系统自动识别称重食材，检
          验员对供应商送达的食材进行检测验收
          保证每日食材品质达标！
        </div>
      </div>
      <div class="processEver">
        <div class="cover">
        <img src="../../assets/imgs/home/zhizuobiaozhunbduan.png">
        <div class="processTxt">食材二次复检入库</div>
        </div>
        <div class="back"> <div class="backIcon">
          <img src="../../assets/imgs/home/zhizuobiaozhunbduan.png">
        </div>
          <span>食材二次复检入库</span><br/>
          通过AI溯源二维码再次追溯复检，确保
          食材入库质量，保障餐饮安全，质量把
          控做到细致入微！

        </div>

      </div>

    </div>
  </div>
  </div>
</template>

<script>
// import WOW from 'wowjs'
export default {
  name: "productIntroduction",
  props:{
    proPk:Boolean
  },
  data(){
    return{

    }
  },
  methods:{


  },
  created() {

  },
  mounted() {


  },



}
</script>

<style lang="scss" scoped>
.productIntroduction{
  position: relative;
  width: 1920px;
  height: 800px;

}
.outer{
  position: relative;
  width: 1920px;
  height: 800px;
  padding-top: 50px;
  box-sizing: border-box;
  background:rgba(222, 234, 218,1);

}
.cultureLine{
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  span{
    font-size: 20px;
  }
}
.greenBox{
  width: 460px;
  height: 10px;
  background: #A7C69C;
}
.greenTxt{
  position: relative;
  width: 280px;
  font-size: 30px;
  text-align: center;
  top: -18px;
  color: #666666;


}
.cultureMain{
  margin: 0 auto;
  width: 950px;
  font-size:14px;
  color: #999999;
}

.processAll{
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
  height: 400px;
  width: 1200px;
  display: flex;
  justify-content: space-around;
  align-items:flex-start;
  flex-wrap: wrap;
}
.processEsp{
  position: relative;
  width: 330px;
  height: 170px;
  background: #EEAA3D;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 25px;
  .backIcon{
    position: absolute;
    right: 5%;
    top: 0%;
    width: 80px;
    height: 80px;
    img{
      width: 80px;
      height:80px;
    }
  }
  span{
    display: block;
    font-size: 24px;
    transform: translateY(6px);
  }
  color: white;
}
.processEver{
  position: relative;
  padding-top: 15px;
  box-sizing: border-box;
  width: 290px;
  margin: 0 20px;
  height: 170px;
  background: #A7C69C;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  transform-style: preserve-3d;
  transition: 0.5s;

  .cover{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    background:  #A7C69C;
  }
  img{
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }

  .processTxt{

    color: white;
    width: 100%;
    text-align: center;

  }
  .back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #EEAA3D;
    color: white;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    transform: translateZ(-1px) rotateY(180deg);
    .backIcon{
      position: absolute;
      right: 5%;
      top: 2%;
      width: 55px;
      height: 55px;
      img{
        width: 55px;
        height: 55px;
      }
    }
    span{
      display: block;
      font-size: 22px;
      transform: translateY(8px);
    }
    color: white;
  }
}
.processEver:hover{
  transition: 0.5s;
  transform: scale(1.14) rotateY(180deg);
  cursor: pointer;
}
</style>
