<template>
  <div class="saleExp animated " :class="{'bounceInRight':expPk}" id="saleExp">
    <div class="exp" v-if="expPk">
    <div class="saleTitle">
      服务案例<br/>
      <span>solve the problem</span>
      <div class="salePics">
        <div class="saleLeft">
          <div class="saleTop">
            <div class="picOne">
              <img src="../../assets/imgs/home/sale1.png" alt="">
            </div>
          </div>
          <div class="saleBom">
            <div class="picTwo">
              <img src="../../assets/imgs/home/sale6.png" alt="">
            </div>
            <div class="picTwo">
              <img src="../../assets/imgs/home/sale5.png" alt="">
            </div>
          </div>
        </div>
        <div class="saleRight">
          <div class="picTwo">
            <img src="../../assets/imgs/home/sale2.png" alt="">
          </div>
          <div class="picTwo">
            <img src="../../assets/imgs/home/sale3.png" alt="">
          </div>
          <div class="picTwo">
            <img src="../../assets/imgs/home/sale4.png" alt="">
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "saleExp",
  props:{
    expPk:Boolean
  }
}
</script>

<style lang="scss" scoped>
.saleExp{
  margin: 0 auto;
  //margin-top: 1rem;
  //margin-bottom: 1rem;
  width: 1920px;
  height: 800px;

  .saleTitle{
    position: relative;
    width: 100%;
    text-align: center;
    color: #A7C69C;
    top: 50px;
    span{
      font-size: 24px;
    }
  }

}
.saleExp>.exp{
  width: 1920px;
  height: 800px;
  background: white;

}
.salePics{
  position: relative;
  margin: 0 auto;
  width: 920px;
  height:530px;
  margin-top: 50px;
  display: flex;
  .saleLeft{
    position: relative;
    width: 600px;
    height: 530px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .saleTop{
      position: relative;
      width:600px;
      height: 330px;
    }
    .saleBom{
      position: relative;
      width: 600px;
      height: 180px;

      flex-wrap: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .saleRight{
    width: 320px;
    margin-left: 20px;
    height: 510px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .picOne{
    width: 600px;
    height: 330px;
    img{
      width: 600px;
      height: 330px;
    }
  }
  .picTwo{
    width: 290px;
    height: 160px;
    img{
      width:290px;
      height: 160px;
    }
  }
}
</style>
