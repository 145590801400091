<template>
  <div class="partner" id="partner">

    <div class="titleLine">
      <div class="lineOne">
        <img src="../../assets/newImgs/home/cooperation.png" alt="">
      </div>
      <div class="lineTwo">

        <div>合<span>作伙</span>伴</div>


      </div>
    </div>
    <div class="partnerMain">
      <div class="partEver">
        <img src="../../assets/newImgs/home/part1.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part2.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part3.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part4.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part5.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part6.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part7.png">
      </div>
      <div class="partEver">
        <img src="../../assets/newImgs/home/part8.png">
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "partner"
}
</script>

<style lang="scss" scoped>
.partner{
  position: relative;
  width: 1920px;
  height: 580px;
  background: #E3E0E0;
  padding-top: 50px;
  box-sizing: border-box;
}
.titleLine{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  img{
    width: 180px;
  }
}
.lineTwo{
  display: flex;
  color: #A7C69C;
  font-size: 36px;
  align-items: center;
  span{
    border-bottom: 7px solid #EEAA3D;
  }
}
.partnerMain{
  margin: 0 auto;
  width: 1100px;
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin-top: 50px;
  .partEver{
    width: 225px;
    height:96px;
    border-radius: 5px;
    overflow: hidden;
    img{
      width: 225px;
      height: 96px;
    }
  }
}
</style>
