<template>
  <div class="newCenter" id="newCenter" :class="{'bounceInTop':newPk}" >
    <div v-if="newPk">
    <div class="newTitle">新闻中心<br/><span>News Center</span></div>
    <div class="choices">
      <div class="choiceEver" :class="{'choiceClick':(newsType=='dynamic')}" @click="changeNews('dynamic')">公司动态</div>
      <div class="choiceEver" :class="{'choiceClick':(newsType=='realInfo')}" @click="changeNews('realInfo')">行业资讯</div>
      <div class="choiceEver" :class="{'choiceClick':(newsType=='mediaCan')}" @click="changeNews('mediaCan')">媒体频道</div>

    </div>
    <component class="animated fadeInUp" :is="newsType"></component>
    </div>
  </div>
</template>

<script>
import dynamic from "./dynamic";
import realInfo from "./realInfo";
import mediaCan from "./mediaCan";
export default {
  name: "newCenter",
  props:{
    newPk:Boolean
  },
  components:{
    dynamic,realInfo,mediaCan
  },
  data(){
    return{
      newsType:"dynamic"
    }
  },
  methods:{
    changeNews(option){
      this.newsType=option;
      this.$store.state.newsType=option;
    }
  }
}
</script>

<style lang="scss" scoped>
.newCenter{
  margin: 0 auto;
  //margin-top: 1rem;
  width: 1920px;
  height: 1000px;
  background: url("../../assets/imgs/home/news.png");
  background-size: 100% 100%;
  padding-top: 100px;
  box-sizing: border-box;

}
.newTitle{
  position: relative;
  margin: 0 auto;

  font-size: 30px;
  color: white;
  width: 100%;

  text-align: center;
  span{
    font-size: 24px;
  }
}
.choices{
  position: relative;
  cursor: pointer;
  margin:  0 auto;
  width: 600px;
  height: 45px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  .choiceEver{
    width: 140px;
    height: 50px;
    border: 1px solid white;
    color: white;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    box-sizing: border-box;
  }
  .choiceClick{
    cursor: pointer;
    background: #EEAA3D;
    border-style: none;

  }
}
</style>
