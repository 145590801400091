<template>
<div class="newsBig" @click="toNewsPage">
<div class="newsPic">
  <img :src="imgPath">
</div>
  <div class="newsTitle">{{newsTitle}}</div>
  <div class="newsContent" v-html="newsContent"></div>
</div>
</template>

<script>
export default {
  name: "newsBig",
  props:{
    imgPath:String,
    newsTitle:String,
    newsContent:String
  },
  data(){
    return{

    }
  },
  methods:{
    toNewsPage(){
      this.$store.state.newsIndex=0;//大新闻默认index为0
      this.$router.push("/newsPage")
    }
  }
}
</script>

<style lang="scss" scoped>
.newsBig{
  position: relative;
  width: 400px;
  height: 350px;
}
.newsPic{
  position: relative;
  width: 400px;
  height: 210px;
  overflow: hidden;
  img{
    width: 100%;
    height:auto;
  }
}
.newsTitle{
  font-size: 14px;
  color: white;
  margin:  10px 0;
}
.newsContent{
  font-size: 12px;
  color: white;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
