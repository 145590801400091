<template>
<div class="dynamic" >
  <div class="dyTitleLine">
    <div class="yellowTitle">浙江国通电气科技有限公司</div>
    <div class="whiteTitle">Company news | 公司动态</div>
    <div class="fakerBox"></div>
  </div>
  <div class="dyContent">
  <div class="newsLeft">
    <news-big :img-path="newsData[0].imgPath" :news-title="newsData[0].newsTitle" :news-content="newsData[0].newsContent"></news-big>
  </div>
  <div class="newsRight">
    <news-small :img-path="newsData[1].imgPath" :news-title="newsData[1].newsTitle" :news-content="newsData[1].newsContent" :news-index="1"></news-small>
    <news-small :img-path="newsData[2].imgPath" :news-title="newsData[2].newsTitle" :news-content="newsData[2].newsContent" :news-index="2"></news-small>
    <news-small :img-path="newsData[3].imgPath" :news-title="newsData[3].newsTitle" :news-content="newsData[3].newsContent" :news-index="3"></news-small>
  </div>
  </div>
</div>
</template>

<script>
import NewsBig from "./newsBig";
import NewsSmall from "./newsSmall";
export default {
  name: "dynamic",
  components: {NewsSmall, NewsBig},
  data(){
    return{
      newsData:[
        {
          imgPath:require("../../assets/imgs/home/news/company1.jpg"),
          newsTitle:"健康餐饮-AI食安溯源",
          newsContent:'“民以食为天,食以安为先”食品安全是目前国内外研究和关注的焦点,食品安全不仅关系到人民的身体健康,生命安全,还关系到国民的经济发展和社会的稳定。<br/>' +
              '<br/>' +
              '     在经济利益的驱动下,目前我国食品安全问题严重,存在着极大的安全隐患。对当前食品安全的问题,基于大数据和人工智能技术构建出食品安全溯源，通过深度学习等人工智能技术,对复杂场景下的高维数据进行深入挖掘分析,化解群体性的食品安全事故。通过合理分工,解决了数据收集、上报、分析到预警的全环节技术难题,提高溯源数据的全覆盖性、实时性、预警准确性和智能化程度。<br/>' +
              '<br/>' +
              '    “AI食安溯源系统”采用人工智能识别技术，对采购食材进行智能识别及食材评价和入库溯源，这套系统全方面诠释了食品安全的溯源追溯体系。'
        },
        {
          imgPath:require("../../assets/imgs/home/news/company2.png"),
          newsTitle:"综合智慧餐饮管理系统—非接触式云食堂",
          newsContent:'综合智慧餐饮管理系统适用于高校、企事业单位应用，是一个迎合移动互联时代潮流，基于现有食堂管理模式，创新打造便捷的食堂订餐及就餐模式，可实现精准备餐/采购、AI智能优化生产、加强就餐者和食堂的互动、 提升就餐体验和食堂服务水平，同时鼓励厨师创新，推陈出新，通过对月度及年度数据的分析比对、科学考核；并对食材质量追根溯源；为食堂提供采购、库存的自动化、数字化的管理，具有安全可靠，维护简单方便的特点。<br/>' +
              '<br/>' +
              '当前“新冠肺炎”疫情给餐饮业按下暂停键；市场呼之而出的四大新诉求：零接触配餐、“分餐公勺”制、集中式操作以及安全健康；浙江国通电气科技有限公司在综合智慧餐饮管理系统上针对疫情，推出“非接触式智慧云食堂”点餐系统，帮助餐饮企业解决当前问题：① 疫情期间，“非接触式智慧云食堂”推出的点餐系统，为广大职工提供多种菜品选择；<br/>' +
              '<br/>' +
              '② 拥有统一工号的职工可根据系统提示进行注册，注册后进入系统进行中餐预订；<br/>' +
              '<br/>' +
              '③领餐时仍以部门为单位领取，各部门派代表时段到食堂领餐，领餐安排以错开时间段进行，并且领餐、取餐排队要隔一段距离。<br/>' +
              '<br/>' +
              '二、“分餐公勺”制<br/>' +
              '<br/>' +
              '    为了守住大家“舌尖上的健康”，众多地区的餐饮行业以及家庭用餐都在推广公筷公勺制、分餐制，践行餐桌文明。分餐制的优点：<br/>' +
              '<br/>' +
              '① 减少交叉感染：“夹菜”是中国独有的饭桌文化，因此，分餐能极大程度减少各种消化道疾病传播的概率，可预防各种经口、唾液传播；<br/>' +
              '<br/>' +
              '② 保证营养均衡：分餐制可以根据每人每餐需要的营养，搭配一定数量的饭菜；控制进食量，防止挑食偏食；<br/>' +
              '<br/>' +
              '③ 减少食材浪费：家庭实行分餐制，掌勺人按需购买、准备食材，在一定程度上能避免食物的浪费。<br/>' +
              '<br/>' +
              '三、AI智能优化生产<br/>' +
              '<br/>' +
              '    采购、库存实现数字量化，并与生产、售卖实现数字化连通，能有效减少损耗浪费，让食堂运转更高效，决策更科学。<br/>' +
              '<br/>' +
              '四、食材安全追根溯源<br/>' +
              '<br/>' +
              '    对食材测评，品控把关，实现食材验收的标准化、可视化，实时采集上传留存比对，通过大数据分析达到对供应商奖优罚劣目的'
        },
        {
          imgPath:require("../../assets/imgs/home/news/company3.jpg"),
          newsTitle:"疫情期间，企业食堂全流程无接触配餐",
          newsContent:'  随着新型冠状病毒肺炎的演进，全国各地均采取了“冰冻社会”的隔离模式来应对快速传播的病毒，这使得无接触商业模式迅速爆发，生鲜电商、在线娱乐等业态迎来高光时刻。<br/>' +
              '<br/>' +
              '    自2月中旬起，企业的复工潮陆续来临，可预计的是，随着复工人数越来越多，疫情中的企业防护服务必将成为新的增长极。<br/>' +
              '<br/>' +
              '在这之中，员工该如何就餐想必是最困扰企业主的问题之一了。之前互联网大厂的食堂福利一直被网友所称道，如今复工后其食堂的无接触新举措同样值得关注<br/>' +
              '<br/>' +
              '特殊时期的非接触式云餐厅<br/>' +
              '<br/>' +
              '    疫情来临之际，作为典型人员聚集场所的团餐，也成为了公共卫生安全防护的重难点区域。怎么在保证安全的前提下，保障员工的饮食不受影响，这已经成为了复工企业的首要问题。<br/>' +
              '<br/>' +
              '    餐饮知名人士介绍：“当前公共卫生安全新态势下，企业食堂有四大新诉求：零接触配餐、非聚集就餐、集中式操作以及安全健康，“非接触式云餐厅”点餐系统正是致力于帮助企业解决这些问题。”<br/>' +
              '<br/>' +
              '    对于企业员工而言，非接触式云餐厅的手机端小程序订餐方案较易操作。首先，食堂会按周发布每餐的供应品种和数量；然后，员工可以通过手机端预约点餐，用企业给每个员工的虚拟点数来支付；紧接着，后厨按单生产，提高效率；随后食堂按照部门打包，由部门代表到食堂领取。'
        },
        {
          imgPath:require("../../assets/imgs/home/news/company4.jpg"),
          newsTitle:"运用物联网AI智能，明确责任，对食物质量追根溯源",
          newsContent:'产销数字化，决策更科学<br/>' +
              '<br/>' +
              '智能食材检测系统是采购、库存实现数字量化，并与生产、售卖实现数字化连通，能有效减少损耗浪费，让食堂运转更高效，决策更科学。<br/>' +
              '<br/>' +
              '1、建立健全的监管制度，杜绝小微权力，做到公开透明；<br/>' +
              '<br/>' +
              '2、展现理论耗用和实际耗用的比对分析，核算菜品成本；<br/>' +
              '<br/>' +
              '3、供应商的评价管理体系，对供应商每次供应进行星级评分，有利优化供应体系；<br/>' +
              '<br/>' +
              '4、称重收货，拍照自动入库。' +
              '安全溯源<br/>' +
              '<br/>' +
              '食品安全是事关人体健康的公共卫生问题，降低疾病隐患，防范食物中毒是食品安全工作的重中之重，智能食材检测系统通过数字化的方式打通上下游数据链，能有效追踪食物来源及去向，食品安全更有保障。'
        }
      ]
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.dynamic{
 width: 900px;
  min-height: 400px;
  margin: 0 auto;

}
.dyTitleLine{
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  font-size: 14px;
  line-height: 50px;
  text-indent: 30px;
  overflow: hidden;
}
.yellowTitle{
  background: #EEAA3D;
  color: white;
  width: 420px;
  height: 100%;
}
.whiteTitle{
  background: white;
  color: #EEAA3D;
  width: 480px;
  height: 100%;
}
.fakerBox{
  position:absolute;
  left: 390px;
  top: 0;
  width: 50px;
  height: 50px;
  background:white;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
}
.dyContent{
  width: 900px;
  min-height: 350px;
  display: flex;
}
.newsRight{
  width: 500px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
</style>
